<template>
  <div class="">
    <h2>待办事项<span v-if="lists.length != 0 ">{{ pageSize }} </span></h2>
    <form ref="filters" action="" v-on:submit.prevent>
      <el-row :gutter="10" class="formInput" :class="{on:formOpen}">
        <el-col  :md="7" :sm="10"  :xs="20">
          <el-row :gutter="10" align="middle" type="flex" justify="space-between">
            <el-col :lg="6" :md="7" :sm="7" :xl="4" :xs="5"><strong>需求标题</strong></el-col>
            <el-col :lg="18" :md="17" :sm="18" :xl="20" :xs="19">
              <el-input size="small" placeholder="请输入需求标题" clearable v-model="filters.name"></el-input>
            </el-col>
          </el-row>

        </el-col>
        <el-col  :md="7" :sm="10"  :xs="20">
          <el-row :gutter="10" align="middle" type="flex" justify="space-between">
            <el-col :lg="6" :md="7" :sm="7" :xl="4" :xs="5"><strong>需求类型</strong></el-col>
            <el-col :lg="18" :md="17" :sm="18" :xl="20" :xs="19">
              <el-select
                  v-model="filters.type"
                  clearable
                  filterable
                  placeholder="请选择需求类型"
                  size="small"
              >
                <el-option
                    v-for="item in typeSelect"
                    :key="item"
                    :label="item"
                    :value="item"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>

        </el-col>
        <el-col  :md="7" :sm="10"  :xs="20">
          <el-row :gutter="10" align="middle" type="flex" justify="space-between">
            <el-col :lg="6" :md="7" :sm="7" :xl="4" :xs="5"><strong>状态</strong></el-col>
            <el-col :lg="18" :md="17" :sm="18" :xl="20" :xs="19">
              <el-select
                  @change="filters.state = (state ? state : 0)"
                  v-model="state"
                  clearable
                  filterable
                  placeholder="请选择状态"
                  size="small"
              >
                <el-option
                    v-for="item in stateSelect"
                    :key="item.id"
                    :label="item.text"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>

        </el-col>
        <el-col  :md="7" :sm="10"  :xs="20">
          <el-row :gutter="10" align="middle" type="flex" justify="space-between">
            <el-col :lg="6" :md="7" :sm="7" :xl="4" :xs="5"><strong>申请人</strong></el-col>
            <el-col :lg="18" :md="17" :sm="18" :xl="20" :xs="19">
              <el-select
                  v-model="filters.client_id"
                  clearable
                  filterable
                  placeholder="请选择申请人"
                  size="small"
              >
                <el-option
                    v-for="item in clientSelect"
                    :key="item.id"
                    :label="item.text"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>

        </el-col>
        <el-col  :md="7" :sm="10"  :xs="20">
          <el-row :gutter="10" align="middle" type="flex" justify="space-between">
            <el-col :lg="6" :md="7" :sm="7" :xl="4" :xs="5">
              <strong>创建人</strong>
            </el-col>
            <el-col :lg="18" :md="17" :sm="18" :xl="20" :xs="19">
              <el-select
                  v-model="filters.creator_id"
                  clearable
                  filterable
                  placeholder="请选择创建人"
                  size="small"
              >
                <el-option
                    v-for="item in creatorSelect"
                    :key="item.id"
                    :label="item.text"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-row>
        </el-col>
        <el-col  :md="7" :sm="10"  :xs="20">
          <el-row :gutter="10" align="middle" type="flex" justify="space-between">
            <el-col :lg="6" :md="7" :sm="7" :xl="4" :xs="5">
              <strong>截止日期</strong>
            </el-col>
            <el-col :lg="18" :md="17" :sm="18" :xl="20" :xs="19">
              <el-date-picker
                  v-model="filters.deadline"
                  :picker-options="pickerOptions"
                  end-placeholder="结束日期"
                  range-separator="至"
                  size="small"
                  start-placeholder="开始日期"
                  type="daterange"
                  value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-col>
          </el-row>
        </el-col>
        <el-col  :md="7" :sm="10"  :xs="20">
          <el-row :gutter="10" align="middle" type="flex" justify="space-between">
            <el-col :lg="6" :md="7" :sm="7" :xl="4" :xs="5">
              <strong>创建时间</strong>
            </el-col>
            <el-col :lg="18" :md="17" :sm="18" :xl="20" :xs="19">
              <el-date-picker
                  v-model="filters.created_at"
                  :picker-options="pickerOptions"
                  end-placeholder="结束日期"
                  range-separator="至"
                  size="small"
                  start-placeholder="开始日期"
                  type="daterange"
                  value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <el-row class="formOpen">
        <el-col :span="24" :class="{on:formOpen}" >
          <i :class="!formOpen ? 'el-icon-arrow-down' : 'el-icon-arrow-up'" @click="formOpen = !formOpen"></i>
        </el-col>
      </el-row>
      <el-button
          class="search"
          icon="el-icon-search"
          size="small"
          type="primary"
          @click="list(filters.page = 1)"
      >查询
      </el-button
      >
      <el-button class="reset" :class="{on:formOpen}" icon="el-icon-refresh-left" size="small" type="reset"  @click="reset"
      >重置
      </el-button
      >
    </form>
    <div class="mainrb">
      <div class="mainrB-t">

        <el-button icon="el-icon-circle-plus-outline" size="small" type="primary"
                   @click="see(null)"
        >
          新建待办
        </el-button>

<!--        <el-button :disabled="detaiolArr.length == 0" icon="el-icon-delete" size="small"-->
<!--                   type="danger" @click="deletes(detaiolArr)">删除-->
<!--        </el-button>-->
        <div class="page-up">
          <el-pagination
              :current-page.sync="filters.page"
              :page-size="filters.per_page"
              :page-sizes="[30, 50, 100]"
              :total="pageSize"
              background
              layout="prev, next"
              size="small"
              @current-change="list">
          </el-pagination>
        </div>
      </div>
      <el-skeleton :count="1" :loading="loading" animated>
        <template slot="template">
          <el-table
              :class="{on:formOpen,ios:$store.state.heights}"
              :data="lists"
          >
            <el-table-column label="需求标题" width="">
              <template>
                <el-skeleton-item variant="p" class="w50"/>
              </template>
            </el-table-column>
            <el-table-column label="需求类型" width="">
              <template>
                <el-skeleton-item variant="p" class="w50"/>
              </template>
            </el-table-column>
            <el-table-column label="申请人" width="">
              <template>
                <el-skeleton-item variant="p" class="w50"/>
              </template>
            </el-table-column>
            <el-table-column label="状态">
              <template>
                <el-skeleton-item variant="p" class="w50"/>
              </template>
            </el-table-column>
            <el-table-column label="截止日期">
              <template>
                <el-skeleton-item variant="p" class="w50"/>
              </template>
            </el-table-column>
            <el-table-column label="创建时间">
              <template>
                <el-skeleton-item variant="p" style="width: 100%"/>
              </template>
            </el-table-column>
            <el-table-column label="创建人">
              <template>
                <el-skeleton-item variant="p" class="w50"/>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template>
                <el-skeleton-item variant="p" class="w50" />
              </template>
            </el-table-column>
          </el-table>
        </template>
        <el-table
            :class="{on:formOpen,ios:$store.state.heights}"
            ref="tableRef"
            :data="lists"
            height="100%"
            style="width: 100%"
            >
          <template slot="empty">
            <el-empty :image-size="80"></el-empty>
          </template>
          <el-table-column type="expand">
            <template slot-scope="props">
              <el-table :data="props.row.communicates" style="width: 94%">
                <el-table-column
                    label="序号"
                    type="index"
                    width="50">
                </el-table-column>
                <el-table-column prop="contact" label="记录人"> </el-table-column>
                <el-table-column prop="contact_content" label="记录描述" :show-overflow-tooltip="true"> </el-table-column>
                <el-table-column prop="created_at" label="创建时间"> </el-table-column>
              </el-table>

            </template>
          </el-table-column>
          <el-table-column
              label="需求标题" prop="name" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column
              label="需求类型"  width="150" >
            <template slot-scope="scope">
              <el-tag
                  v-for="item in scope.row.types"
                  :key="item.label"
                  type=""
                  size="mini" :class="{'m-r5':scope.row.types.length > 1}">
                {{ item == "苏韬" ? '法律' : item }}
              </el-tag>

            </template>
          </el-table-column>

          <el-table-column
              label="申请人" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <div class="" v-for="item in [scope.row.client]">
                <router-link  v-if="item.id != 0" :to="{
                  name: 'newlyBuild',
                    params: { id:  item.id  },
                }" target="_blank">
                  {{ item.name }}
                </router-link>
                <span v-else>
                {{item.name || '-'}}
              </span>
              </div>

            </template>
          </el-table-column>
          <el-table-column
              label="状态"  width="80">
            <template slot-scope="scope">
              <el-tag size="mini" :type="scope.row.state == 1 ? '' : (scope.row.state == 2 ? 'info' : 'success') ">
                {{ scope.row.state == 1 ? '进行中' : (scope.row.state == 2 ? '已关闭' : '已完结') }}
              </el-tag>
            </template>
          </el-table-column>

          <el-table-column
              label="截止日期"  width="110">
            <template slot-scope="scope">
              {{scope.row.deadline || '-'}}
            </template>
          </el-table-column>
          <el-table-column
              label="创建时间"  width="110">
            <template slot-scope="scope">
              {{scope.row.created_at || '-'}}
            </template>
          </el-table-column>
          <el-table-column
              label="创建人" >
            <template slot-scope="scope">
<!--              <span v-for="item in [scope.row.creator]">-->
<!--                {{item.name || '-'}}&nbsp;-->
<!--              </span>-->
              {{user.agent.name}}
            </template>
          </el-table-column>
          <el-table-column  label="操作" width="180">
            <template slot-scope="scope">
              <el-button icon="el-icon-edit-outline" size="small" type="text"
                         @click="see(scope.row.id)">详情
              </el-button>
              <el-button
                  v-if="scope.row.state == 2 && (scope.row.member_id == user.id || user.parent_id == 0)"
                  icon="el-icon-delete"
                  size="small"
                  type="text"
                  class="delete"
                  @click.native.prevent="deletes(scope.$index, lists,scope.row.id)"
                 >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-skeleton>
      <!-- 分页 -->
      <el-pagination
          :current-page.sync="filters.page"
          :page-size="filters.per_page"
          :page-sizes="[30, 50, 100]"
          :total="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="changeRowNum"
          @current-change="list()"
      >
      </el-pagination>
    </div>

  </div>
</template>

<script>
import storage from '@/utils/storage'
import {schedules, schedulesDelete} from '@/api/personal.js'

export default {
  name:'DealtMatter',
  data() {
    return {
      user:storage.getUser(),
      array:{
        ids:[]
      },
      loadSave:false,
      loadClose:false,
      formOpen:false,
      loading: true,//控制列表的骨架屏
      lists: [],
      pickerOptions: {//日期选择器的便利选择对象
        shortcuts: storage.getShortcuts(),
      },
      filters: {
        page: 1,//当前页
        per_page: 30,//每页数量
        type:'',//需求类型
        deadline: [],//截止日期
        created_at:[],//创建时间
        name:'',//需求标题
        state:0,//状态
        client_id: '',//申请人id
        creator_id: '',//创建人
      },
      state:'',
      pageSize: 0, //总条数
      finishArr:[],
      detaiolArr:[],
      selectList: [],
      btnSh: true,
      stateSelect: [
        {id: '1', text: '进行中'},
        {id: '2', text: '已关闭'},
        {id: '3', text: '已完结'},
      ],
      creatorSelect: [],
      clientSelect: [],
      typeSelect:['商标','版权','法律'],
      finish: true,

    }
  },
  created() {
    this.select()
    this.list()
  },

  methods: {
    // 下拉
    async select() {
      let agent = storage.getUser().agent
      agent['text'] = agent['name']
      let s = await storage.getSelects('app_names', 'users')

      this.clientSelect = s.app_names
      this.creatorSelect =  s.users.concat(agent) //storage.getItem('users')

    },
    changeRowNum(val) {
      this.filters.per_page = val;
      this.list();
    },
    async list() {
      this.loading = true;
      this.lists = storage.getList();
      try {
        const res = await schedules(this.filters)
        if (res && res.err_code === 0) {
          this.pageSize = res.data.total
          this.loading = false;
          this.lists = res.data.list
        } else {
          this.loading = false;
          this.lists = []
        }
      } catch (e) {
        this.loading = false;
        this.lists = []
      }
    },
    //重置事件
    reset() {
      this.filters = this.$options.data().filters;
      this.list(this.filters.page = 1)
    },
    //打开详情
    see(val) {
   let routerJump = this.$router.resolve({name:"dealtMatterDetail",query:{id:val}})
      window.open(routerJump.href, '_blank')
    },
    //删除待办
    deletes(index,rows,id) {
      try {
        this.$confirm('确定要删除该待办事项吗？删除后将无法恢复！', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          const res = await schedulesDelete({id: id})
          if (res && res.err_code === 0) {
            this.$message({
              type: "success",
              message: "待办事项已删除！",
            });
            rows.splice(index, 1);
          }else{
            this.$message({
              message: res.err_msg,
              type: 'warning'
            });
          }
        })
      } catch (e) {

      }
    },


  }
}
</script>

<style lang="less" scoped>
@import '../../style/dealtMatter.less';
.delete{
  color: #f56c6c;
}
</style>